var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("Jelszó módosítás")]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ sm: 9, md: 7, lg: 9, xl: 9 },"wrapper-col":{ sm: 10, md: 12, lg: 6, xl: 6 }},on:{"submit":_vm.changePassword}},[_c('a-form-item',{attrs:{"label":"Régi jelszó"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['oldPassword', { rules: [{ required: true, message: 'Kérjük, adja meg a régi jelszavát!' }] }]),expression:"['oldPassword', { rules: [{ required: true, message: 'Kérjük, adja meg a régi jelszavát!' }] }]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Új jelszó"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'newPassword',
					{ rules: [{ required: true, message: 'Kérjük, adja meg a használni kívánt, új jelszót!' }] },
				]),expression:"[\n\t\t\t\t\t'newPassword',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Kérjük, adja meg a használni kívánt, új jelszót!' }] },\n\t\t\t\t]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Új jelszó mégegyszer"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'newPassword2',
					{ rules: [{ required: true, message: 'Kérjük, ismételje meg a választott, új jelszót!' }] },
				]),expression:"[\n\t\t\t\t\t'newPassword2',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Kérjük, ismételje meg a választott, új jelszót!' }] },\n\t\t\t\t]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapper-col":{
				xs: { span: 6, offset: 1 },
				sm: { span: 6, offset: 9 },
				md: { span: 6, offset: 9 },
				lang: { span: 6, offset: 9 },
			}}},[_c('a-button',{staticStyle:{"margin-bottom":"1em"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Küldés")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }