<template>
	<div>
		<h2>Jelszó módosítás</h2>
		<a-form
			:form="form"
			:label-col="{ sm: 9, md: 7, lg: 9, xl: 9 }"
			:wrapper-col="{ sm: 10, md: 12, lg: 6, xl: 6 }"
			@submit="changePassword"
		>
			<a-form-item label="Régi jelszó">
				<a-input
					type="password"
					v-decorator="['oldPassword', { rules: [{ required: true, message: 'Kérjük, adja meg a régi jelszavát!' }] }]"
				/>
			</a-form-item>
			<a-form-item label="Új jelszó">
				<a-input
					type="password"
					v-decorator="[
						'newPassword',
						{ rules: [{ required: true, message: 'Kérjük, adja meg a használni kívánt, új jelszót!' }] },
					]"
				/>
			</a-form-item>
			<a-form-item label="Új jelszó mégegyszer">
				<a-input
					type="password"
					v-decorator="[
						'newPassword2',
						{ rules: [{ required: true, message: 'Kérjük, ismételje meg a választott, új jelszót!' }] },
					]"
				/>
			</a-form-item>
			<a-form-item
				:wrapper-col="{
					xs: { span: 6, offset: 1 },
					sm: { span: 6, offset: 9 },
					md: { span: 6, offset: 9 },
					lang: { span: 6, offset: 9 },
				}"
				style="text-align: center"
			>
				<a-button type="primary" html-type="submit" style="margin-bottom: 1em">Küldés</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
export default {
	name: 'ChangePassword',

	data() {
		return {
			form: this.$form.createForm(this, { name: 'changePassword' }),
			formLayout: 'vertical',
		};
	},

	methods: {
		changePassword(e) {
			e.preventDefault();
			var newPassword = this.form.getFieldValue('newPassword'),
				newPassword2 = this.form.getFieldValue('newPassword2'),
				oldPassword = this.form.getFieldValue('oldPassword'),
				errors;
			this.form.validateFields();
			errors = this.form.getFieldsError();
			if (errors.newPassword !== undefined || errors.newPassword2 !== undefined) {
				return false;
			}
			if (newPassword != newPassword2) {
				this.$showNotification(
					'Hiba',
					'A megadott jelszavak nem egyeznek meg. Kérjük, gépelje be őket újra!',
					6,
					<a-icon type="close-circle" style="color:#fc0a33" />
				);
				return false;
			}
			this.$store
				.dispatch('changePassword', {
					currentpassword: oldPassword,
					password: newPassword,
				})
				.then(() => {
					this.form.resetFields();
					this.$showNotification(
						'Rendben',
						'A jelszó módosítása sikeresen megtörtént!',
						6,
						<a-icon type="check-circle" style="color:#00ce55" />
					);
					this.$router.push({ name: 'VehicleList' });
				})
				.catch(err => {
					this.$showNotification(
						'Hiba',
						err.response.data.detail,
						6,
						<a-icon type="close-circle" style="color:#fc0a33" />
					);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
// @import '@/styles/components/_contracts';
.btn-info.ant-btn, .ant-btn-primary.ant-btn {
	background: #6d8cb5!important;
	border-color: #6d8cb5 !important;
}

@media (max-width: 576px) {
	/* Target devices narrower than 578px. */
	.ant-form {
		padding: 0 1em;
	}

	h2 {
		font-size: 1.5em;
	}
}
</style>
